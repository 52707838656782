import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function Card({ title, text, icon, link = null }) {
  return (
    <div className="card neumorph col-sm-12 col-lg-3 mx-lg-4 my-sm-4 my-lg-2">
      <div className="card-body">
        {
          link && (<a href={link} target="_blank" rel="noreferrer"><FontAwesomeIcon icon={icon} className="icon mb-2" /></a>)
        }
        {
          !link && (<FontAwesomeIcon icon={icon} className="icon mb-2" />)
        }
        <h2 className="card-subtitle text-center">
          {title}
        </h2>
        <div className="card-text text-center mt-2">
          {text}
        </div>
      </div>
    </div>
  )
}
