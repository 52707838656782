import React, { useEffect, useState } from 'react'
import Card from '../components/Card'
import Layout from '../components/Layout'

export default function Projects() {
  const [projects, setProjects] = useState([])
  useEffect(() => {
    fetch('https://api.github.com/users/taher07/repos?type=owner&sort=full_name&sort=asc', {
      method: 'GET'
    })
      .then(res => res.json())
      .then(r => setProjects(r))
      .catch(err => console.log(err));
  }, [])
  return (
    <Layout>
      <div className="col-sm-12 row justify-content-center">
        {
          projects.map((project, i) => (<Card key={i} title={project.name} text={project.description} icon={['fab', 'github']} link={project.html_url} />))
        }
      </div>
    </Layout>
  )
}
