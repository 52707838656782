import React, { useEffect, useState } from 'react'
import Layout from '../components/Layout'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import emailjs from 'emailjs-com';
import { USERID, SERVICEID, TEMPLATEID } from '../keys'

export default function Contact() {
  const [filled, setFilled] = useState(false)
  const [values, setValues] = useState({
    name: '',
    email: '',
    message: ''
  })
  const [error, setError] = useState({
    name: false,
    email: false,
    message: false
  })
  const [showLoader, setShowLoader] = useState(false)
  const handleChange = n => e => {
    setValues({ ...values, [n]: e.target.value })
  }
  useEffect(() => {
    if (localStorage.getItem('name') !== null)
      setValues(values => ({ ...values, name: localStorage.getItem('name') }))

    if (localStorage.getItem('message') !== null)
      setValues(values => ({ ...values, message: localStorage.getItem('message') }))

    if (localStorage.getItem('email') !== null)
      setValues(values => ({ ...values, email: localStorage.getItem('email') }))
  }, [])
  const onSubmit = () => {
    if (values.name)
      localStorage.setItem('name', values.name);
    if (values.email)
      localStorage.setItem('email', values.email);
    if (values.message)
      localStorage.setItem('message', values.message);
    setShowLoader(true)
    if (!values.name) {
      setError({
        ...error,
        name: true,
      })
      setShowLoader(false)
      return
    }
    else
      setError({
        ...error,
        name: false,
      })
    if (!values.email) {
      setError({
        ...error,
        email: true,
      })
      setShowLoader(false)
      return
    }
    else
      setError({
        ...error,
        email: false,
      })
    if (!values.message) {
      setError({
        ...error,
        message: true,
      })
      setShowLoader(false)
      return
    }
    else
      setError({
        ...error,
        message: false,
      })
    localStorage.removeItem('name')
    localStorage.removeItem('email')
    localStorage.removeItem('message')
    fetch(`https://apilayer.net/api/check?access_key=e89bda6cc7eface9f7fa1bdf96e5f40f&email=${values.email}&smtp=1&format=1`)
      .then(res => res.json())
      .then(r => {
        if (r.mx_found && r.smtp_check) {
          emailjs.send(SERVICEID, TEMPLATEID, values, USERID)
            .then(() => {
              setFilled(!filled)
            })
            .catch(err => {
              console.log(err)
            })
          setShowLoader(false)
        }
        else {
          setError({
            ...error,
            email: true
          })
          setShowLoader(false)
        }
      })
      .catch(err => {
        console.log(err)
        setShowLoader(false)
      })
  }
  return (
    <Layout>
      {
        !filled && !showLoader && (
          <div className="col-sm-12">
            <div className="form-group">
              <label htmlFor="name" className="minor-title">Name</label>
              {
                (error.name) && (
                  <div className="col-sm-12 alert alert-warning">
                    Please enter a name!
                  </div>
                )
              }
              <input type="text" className="form-control" onChange={handleChange('name')} value={values.name} required />
            </div>
            <div className="form-group">
              <label htmlFor="email" className="minor-title">Email</label>
              {
                (error.email) && (
                  <div className="col-sm-12 alert alert-warning">
                    Please enter a valid email!
                  </div>
                )
              }
              <input type="email" className="form-control" onChange={handleChange('email')} value={values.email} required />
            </div>
            <div className="form-group">
              <label htmlFor="message" className="minor-title">Message</label>
              {
                (error.message) && (
                  <div className="col-sm-12 alert alert-warning">
                    Please enter a message!
                  </div>
                )
              }
              <textarea className="form-control" rows="5" onChange={handleChange('message')} value={values.message} required></textarea>
            </div>
            <button className="btn neumorph button2 rounded w-100 my-3" onClick={onSubmit}>Lets connect over a cup of coffee!</button>
          </div>
        )
      }
      {
        filled && !showLoader && (
          <div className="col-sm-12 text-center py-5">
            <div className="major-title">Thank you for connecting with me! <FontAwesomeIcon icon="grin-alt" /></div>
            <div className="major-title">See you soon!</div>
          </div>
        )
      }
      {
        showLoader && (
          <div className="col-sm-12 py-5">
            <div className="loader mx-auto">
              <div className="loader__bar"></div>
              <div className="loader__bar"></div>
              <div className="loader__bar"></div>
              <div className="loader__bar"></div>
              <div className="loader__bar"></div>
              <div className="loader__ball"></div>
            </div>
            <div className="col-sm-12 my-2 w-100 text-center" style={{ textTransform: 'uppercase', letterSpacing: 3, fontSize: 12, fontWeight: 600 }}>loading</div>
          </div>
        )
      }
    </Layout>
  )
}
