import React from 'react'
import image from '../img.png'
import buymeacoffee from '../bmc.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function SocialBlock() {
  return (
    <div className="col-sm-12 social">
      <div className="mb-2 rounded-circle" id="myImage">
        <img src={image} className="img-fluid img-thumbnail" alt="" />
      </div>
      <a href="https://buymeacoff.ee/tnode" target="_blank" rel="noreferrer" className="btn neumorph button2 rounded-pill" id="buymeacoffee">
        <img src={buymeacoffee} className="img-fluid" alt="" />
      </a>
      <div className="col-12 row mt-3 w-100 justify-content-center">
        <a href="https://linkedin.com/in/taherlunavadi" target="_blank" rel="noreferrer" className="col-3">
          <FontAwesomeIcon icon={['fab', 'linkedin']} className="icon" id="linkedin" />
        </a>
        <a href="https://www.instagram.com/taher_813/" target="_blank" rel="noreferrer" className="col-3">
          <FontAwesomeIcon icon={['fab', 'instagram']} className="icon" id="instagram" />
        </a>
        <a href="https://twitter.com/taherlunavadi" target="_blank" rel="noreferrer" className="col-3">
          <FontAwesomeIcon icon={['fab', 'twitter']} className="icon" id="twitter" />
        </a>
        <a href="https://t.me/taherlunavadi" target="_blank" rel="noreferrer" className="col-3">
          <FontAwesomeIcon icon={['fab', 'telegram']} className="icon" id="telegram" />
        </a>
      </div>
    </div>
  )
}
