import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import logo from '../logo.png'
import '../style.css'

const Header = ({ history }) => {
  return (
    <div className="mt-3 row">
      <div className="col-2">
        <img src={logo} className="logo" alt="TL" />
      </div>
      <ul className="col-4 offset-6 navbar navbar-expand justify-content-end">
        <li className="nav-link col-sm-4">
          <Link to="/">About</Link>
        </li>
        <li className="nav-link col-sm-4">
          <Link to="/projects">Projects</Link>
        </li>
        <li className="nav-link col-sm-4">
          <Link to="/contact">Contact</Link>
        </li>
      </ul>
    </div>
  )
}
export default withRouter(Header)