import React from 'react'
import Layout from '../components/Layout'
import SocialBlock from '../components/SocialBlock'
import Card from '../components/Card'
import { useHistory } from 'react-router-dom'

export default function Home() {
  const history = useHistory()
  return (
    <Layout>
      <div className="col-12 row p-0 m-0">
        <div className="col-sm-12 col-lg-6 d-none d-lg-block">
          <h3 className="minor-title mb-5">Hi, my name is Taher.</h3>
          <h2 className="major-title">I’m a full-stack developer and a cloud enthusiast.</h2>
          <div className="my-4">
            <button className="btn neumorph button2 rounded-pill mx-3" onClick={() => history.push('projects')}>My Work</button>
            <button className="btn neumorph button1 rounded-pill mx-3" onClick={() => history.push('contact')}>Let's talk</button>
          </div>
        </div>
        <div className="col-sm-12 col-md-6 offset-md-3 col-lg-3 offset-lg-3">
          <SocialBlock />
        </div>
        <div className="col-sm-12 col-lg-6 d-lg-none text-center">
          <h3 className="minor-title my-3">Hi, my name is Taher.</h3>
          <h2 className="major-title">I’m a full-stack developer and a cloud enthusiast.</h2>
          <div className="my-2">
            <button className="btn neumorph button2 rounded-pill mx-3 my-2" onClick={() => history.push('projects')}>My Work</button>
            <button className="btn neumorph button1 rounded-pill mx-3 my-2" onClick={() => history.push('contact')}>Let's talk</button>
          </div>
        </div>
        <div className="col-sm-12 row mb-2">
          <h3 className="minor-title my-2 col-sm-12">What I do</h3>
          <div className="col-sm-12 d-lg-flex justify-content-center">
            <Card title="Development" text="Need help developing your website/project?  Well, I’m there to get you through all of it" icon="file-code" />
            <Card title="Consultation" text="Seek advice or wondering how could your product scale up? Well, you’ve come to the right place" icon="flask" />
            <Card title="Coaching" text="Have a slight tint of an idea? Well, I’m here to coach you all through the journey" icon="hands-helping" />
          </div>
        </div>
      </div>
    </Layout>
  )
}
