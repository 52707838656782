import React from 'react'
import '../style.css'
import Header from './Header'

export default function Layout({ children }) {
  return (
    <div className="container-fluid">
      <div className="mainContent glassmorph col-md-8 offset-md-2 my-5 p-2">
        <Header />
        {children}
      </div>
    </div>
  )
}
